.email-signup-banner .newsletter-form,
.email-signup-banner .newsletter-form__field-wrapper {
  display: inline-block;
}

@media only screen and (min-width: 750px) {
  .email-signup-banner:not(.banner--desktop-transparent) .email-signup-banner__box {
    width: 100%;
  }
}

.email-signup-banner__box .email-signup-banner__heading {
  margin-bottom: 0;
}

.email-signup-banner__box > * + .newsletter__subheading {
  margin-top: 2rem;
}

.email-signup-banner__box .newsletter__subheading p {
  margin: 0;
}

.email-signup-banner-background {
  width: 100%;
  height: 100%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 749px) {
  .email-signup-banner:not(.banner--mobile-bottom) .banner__box:not(.email-signup-banner__box--no-image) {
    background-color: transparent;
    --color-foreground: 255, 255, 255;
    --color-button: 255, 255, 255;
    --color-button-text: 0, 0, 0;
  }
}

@media only screen and (min-width: 750px) {
  .banner--desktop-transparent .email-signup-banner__box--no-image * {
    color: rgb(var(--color-foreground));
  }

  .banner--desktop-transparent .email-signup-banner__box .field__input {
    background-color: transparent;
  }

  .banner--desktop-transparent .email-signup-banner__box--no-image .field__input {
    box-shadow: 0 0 0 0.1rem rgba(var(--color-foreground), 0.55);
  }

  .banner--desktop-transparent .email-signup-banner__box--no-image .field__input:focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--color-foreground), 0.75);
  }

  .banner--desktop-transparent .email-signup-banner__box--no-image .field__button:focus-visible {
    outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
    box-shadow: 0 0 0 0.3rem rgb(var(--color-background)), 0 0 0.5rem 0.4rem rgba(var(--color-foreground), 0.3);
  }
}

@media only screen and (min-width: 750px) {
  .email-signup-banner-background-mobile {
    display: none;
  }
}

@media only screen and (max-width: 749px) {
  .email-signup-banner-background:not(.email-signup-banner-background-mobile) {
    display: none;
  }
}

.email-signup-banner .banner__media {
  overflow: hidden;
}

@media screen and (max-width: 749px) {
  .banner--mobile-content-align-left .newsletter-form__message {
    justify-content: flex-start;
  }

  .banner--mobile-content-align-right .newsletter-form__message {
    justify-content: right;
  }
}

@media screen and (min-width: 750px) {
  .banner--content-align-center .newsletter-form__message {
    justify-content: center;
  }

  .banner--content-align-right .newsletter-form__message {
    justify-content: right;
  }
}
